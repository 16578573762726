/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSdOYUhSYIyEosRvhTtuZ5J7QNssu9iBf1WbL4sbRxdbngVxVg/viewform?embedded=true"
      sx={{
        width: "100%",
        height: ["900px", "780px"],
        ml: [-2, -4],
      }}
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Contact Form"
    >
      Loading…
    </iframe>
  </Layout>
)

export default ContactPage
